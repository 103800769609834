import styled from "styled-components";
import Img from "gatsby-image";

import * as V from "styles/variables";

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${V.MaxSize.sm};
  padding-left: ${V.Space.default};
  padding-right: ${V.Space.default};
`;

export const Image = styled(Img).attrs((props) => ({
  alt: props.title,
}))`
  display: block;
  margin-bottom: ${V.Space.default};
`;
