import styled from "styled-components";
import media from "styled-media-query";

import * as V from "styles/variables";
import * as T from "styles/typography";

export const Content = styled.article`
  ${T.Text1}

  &:not(:first-child) {
    margin-top: ${V.Space.md};
  }

  &:not(:last-child) {
    margin-bottom: ${V.Space.md};
  }

  h1 {
    ${T.Heading1}

    &:not(:first-child) {
      margin-top: ${V.Space.lg};
    }

    &:not(:last-child) {
      margin-bottom: ${V.Space.xxs};
    }
  }

  h2 {
    ${T.Heading2}

    &:not(:first-child) {
      margin-top: ${V.Space.lg};
    }

    &:not(:last-child) {
      margin-bottom: ${V.Space.default};
    }
  }

  h3 {
    ${T.Heading3}

    &:not(:first-child) {
      margin-top: ${V.Space.md};
    }

    &:not(:last-child) {
      margin-bottom: ${V.Space.sm};
    }
  }

  hr {
    // ${T.Heading3}

    &:not(:first-child) {
      margin-top: ${V.Space.xs};
    }

    &:not(:last-child) {
      // margin-bottom: ${V.Space.sm};
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: ${V.Space.default};
    }
  }

  a {
    ${T.Link}
  }

  blockquote {
    color: var(--textColor);
    font-size: 2.2rem;
    // font-weight: 600;
    // letter-spacing: 0.025px;
    line-height: 1.8;
    font-family: "sans-serif";
    border-left: ${V.Space.xxs} solid ${V.Color.Black};
    // font-style: italic;
    overflow-wrap: break-word;
    // padding-bottom:  ${V.Space.sm};
    padding-left: ${V.Space.default};
    // padding-top:  ${V.Space.sm};
    font-size: 16px;

    &:not(:first-child) {
      margin-top: ${V.Space.md};
    }

    &:not(:last-child) {
      margin-bottom: ${V.Space.md};
    }
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  ol,
  ul {
    padding-left: 2.5rem;

    &:not(:last-child) {
      margin-bottom: ${V.Space.default};
    }
  }

  li {
    &:not(:first-child) {
      margin-top: ${V.Space.sm};
    }

    &:not(:last-child) {
      margin-bottom: ${V.Space.xs};
    }
  }

  img,
  .gatsby-resp-image-wrapper {
    width: 100%;

    & + em {
      font-size: 1.4rem;
    }
  }

  .gatsby-highlight {
    pre[class*="language-"] {
      // background-color: var(--bgCode);
      // border-left: ${V.Space.xxs} solid var(--highlightColor);
      font-size: 16px;
      padding-left: ${V.Space.default};

      ${media.lessThan("medium")`
        border-radius: 0;
        font-size: 16px;
        margin-left: -${V.Space.sm};
        margin-right: -${V.Space.sm};
      `}
    }

    &:not(:last-child) {
      margin-bottom: ${V.Space.default};
    }
  }

  .language-text {
    background-color: var(--bgSecondary);
    color: var(--colorPrimary);
    font-size: 16px;
    padding-bottom: 0em;
    padding-left: 0em;
    padding-right: 0em;
    padding-top: 0em;
    text-shadow: none;
  }

  hr {
    background-color: var(--borderColor);
    border: 0;
    height: 2px;
    margin-bottom: ${V.Space.default};
    margin-top: ${V.Space.default};
  }

  .twitter-tweet {
    ${T.Text2}

    p {
      ${T.Heading3}
      border-bottom: ${V.Border.box};
      font-style: normal;
      margin-bottom: ${V.Space.sm};
      padding-bottom: ${V.Space.sm};
    }

    a {
      ${T.Text2}

      &:before {
        content: "";
        display: block;
      }
    }
  }

  .support-title {
    ${T.Subtitle1}
  }
`;
