import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Social from "components/Social";

import Content from "components/Content";

import * as V from "styles/variables";
import * as T from "styles/typography";

import * as S from "./styled";

const Image = ({ src, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { internal: { mediaType: { regex: "/image/" } } }
      ) {
        edges {
          node {
            relativePath
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const match = useMemo(
    () => data.images.edges.find(({ node }) => src === node.relativePath),
    [data, src]
  );

  if (!match) return null;

  const { node: { childImageSharp, publicURL, extension } = {} } = match;

  if (extension === "svg" || !childImageSharp) {
    return <img src={publicURL} {...rest} />;
  }

  return <Img fluid={childImageSharp.fluid} {...rest} />;
};

const Author = ({ language, fullText }) => {
  return (
    // <Content>
    // {/* <div itemScope itemType="http://schema.org/Person"> */}
    // <div>
    // <div>
    //   {/* <Image
    //     src="suhail.png"
    //     className="mx-auto shadow-xl"
    //     alt="Suhail"
    //     style={{
    //       border: "0px solid green",
    //       width: "150px",
    //       height: "150px",
    //       margin: "auto",
    //     }}
    //   /> */}
    // {/* </div> */}
    <S.Container>
      <Content>
        <div>
          <h1
            style={{
              // border: "2px solid var(--primaryColor)",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Suhail Saqan
          </h1>
        </div>
        <br />
        <div
          style={
            {
              // border: "2px solid var(--primaryColor)",
            }
          }
        >
          {/* <p
            style={{
              fontFamily: "Lato, 'Helvetica Neue', sans-serif !important",
              fontSize: "15px",
              marginBottom: "50px",
              lineHeight: "1.5",
              fontWeight: "500",
            }}
          >
            I am an Engineer at <a href="https://thebitcoincompany.com">The Bitcoin Company</a> and studying Electrical Engineering at San
            Diego State University.
          </p> */}
          {/* <p
            style={{
              fontFamily: "Valkyrie B",
              fontSize: "14pt",
              marginBottom: "1em",
              lineHeight: "1.5",
              fontWeight: "400",
            }}
          >
            Most recently, I've been working on Bitcoin and cryptography by
            building multiple projects and tools. My most recent project is
            Rapit which allows people to take out peep to peer loans in a
            cheap non-custodial manner using the Bitcoin Lightning Network. I
            also contribute to open-source projects like Bitcoin Core and write a
            <a href="/blog"> blog</a> in my free time.
          </p>
          <p
            style={{
              fontFamily: "Valkyrie B",
              fontSize: "14pt",
              marginBottom: "1em",
              lineHeight: "1.5",
              fontWeight: "400",
            }}
          >
            I also like reading the Bible, Austrian economics books, and listening to
            Hip-Hop.
          </p> */}
          <p
            style={{
              fontFamily: "Lato, 'Helvetica Neue', sans-serif !important",
              fontSize: "15px",
              marginBottom: "10px",
              lineHeight: "1.5",
              fontWeight: "500",
            }}
          >
            Email: {" "}
            <a href="mailto:suhail.saqan@gmail.com">suhail.saqan@gmail.com</a>
            <br />
            Nostr: {" "}
            <a href="nostr:npub1k92qsr95jcumkpu6dffurkvwwycwa2euvx4fthv78ru7gqqz0nrs2ngfwd">npub1k92qsr95jcumkpu6dffurkvwwycwa2euvx4fthv78ru7gqqz0nrs2ngfwd</a>
            {/* <a href={require("/content/pgp/publickeyname.pdf")} download>
            Download my PGP Key
          </a> */}
          </p>

          {/* <iframe
          src={require("/content/pdf/Bitcoin, a Peaceful Protest for the Palestinians.pdf")}
          width="100%"
          height="500px"
        ></iframe> */}

          <div
            style={{
              paddingLeft: "auto",
              fontFamily: "Valkyrie B",
              marginBottom: "0px",
              alignItems: "Center",
            }}
          >
            <Social />
          </div>
        </div>
      </Content>
    </S.Container>
    // {/* </> */}
    // </div>
    // </Content>
  );
};

Author.propTypes = {
  language: PropTypes.string,
  fullText: PropTypes.bool,
};

export default Author;
